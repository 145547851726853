import React from "react"

export default function useDetectTouchDevice() {
  const [isTouchDevice, setTouchDevice] = React.useState(null)

  React.useEffect(() => {
    const touchdevice =
      ("ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0) && 
      !navigator.userAgent.includes('Surface')
    setTouchDevice(touchdevice)
    
  }, [])

  return { isTouchDevice }
}
