import React from "react"
import { motion } from "framer-motion"
import { MousePosition, useStateContext } from "@context/stateContext"
import useDetectTouchDevice from "@hooks/useDetectTouchDevice"
import lobster from "@images/lobster.png"
interface Props {
  mousePosition?: MousePosition
  setMousePosition?: (val: MousePosition) => void
  mouseInteracting?: boolean
  headColor?: string
}
const SpecialCursor: React.FC<Props> = ({ headColor }) => {
  const { setMousePosition, mouseInteracting, mouseWhite, lobsterGame } = useStateContext()
  const { isTouchDevice } = useDetectTouchDevice()
  const cursorRef = React.useRef()

  React.useLayoutEffect(() => {
    const mouseMove = e => {
      cursorRef.current.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`
    }
    const mouseDown = e => {
      !lobsterGame &&
        setMousePosition({
          x: e.clientX,
          y: e.clientY,
        })
    }
    const touchEnd = e => {
      const touches = e.changedTouches
      !lobsterGame &&
        setMousePosition({
          x: touches[0].pageX,
          y: touches[0].pageY,
        })
    }
    if (!isTouchDevice) {
      if (cursorRef.current) {
        window.addEventListener("mousemove", mouseMove)
        window.addEventListener("mousedown", mouseDown)
      }
    }
    if (isTouchDevice) {
      window.addEventListener("touchend", touchEnd)
    }
    return () => {
      window.removeEventListener("touchend", touchEnd)
      window.removeEventListener("mousemove", mouseMove)
      window.removeEventListener("mousedown", mouseDown)
    }
  }, [])
  return (
    <motion.span ref={cursorRef} className={`rounded-full fixed left-0 top-0 pointer-events-none z-[99999]`}>
      {!isTouchDevice && isTouchDevice !== null && (
        <motion.svg
          className={`transform transition-all duration-300 ease-in-out block relative -translate-x-[28px] -translate-y-[25px]`}
          width={56}
          height={51}
          viewBox="0 0 66 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <motion.path
            d="m26.728186377071673,23.63407217901065 v-9.353823235739036 c0,-9.809097818009517 -5.918569569516292,-11.568113249509116 -13.720320365696855,-12.16824792613839 v-0.5794403774351616 h22.929283507077102 c-1.8831812266642742,3.1869220758933876 -2.710953194428791,5.194269097722341 3.3110878710580662,5.194269097722341 v16.90724244159025 h25.1642678200413 v12.16824792613839 h-25.1642678200413 v22.55678612158307 h-12.52005101243831 v-22.55678612158307 h-24.81246473374138 v-12.16824792613839 z"
            className={headColor === "black" ? (mouseWhite ? "fill-white" : "fill-black dark:fill-[#f1fc00]") : mouseInteracting ? "fill-[#fb18e0]" : "fill-[#f1fc00]"}
          />
        </motion.svg>
      )}
      {/* {lobsterGame && !isTouchDevice && isTouchDevice !== null && <img src={lobster} className={`block relative -translate-x-1/2 top-0 -translate-y-1/2`} />} */}
    </motion.span>
  )
}

export default SpecialCursor
