import * as React from "react"
import NavBar from "@components/root/NavBar"
import SpecialCursor from "@components/elements/SpecialCursor"
import Logo from "./Logo"
import LobDinoGame from "@components/modules/LobDinoGame"

type LayoutProps = {
  children: React.ReactNode
  data?: object
  location?: object
}

const Layout = ({ children, data, location }: LayoutProps) => {
  return (
    <>
      <SpecialCursor headColor={data?.main?.contentTypeName === "jobs" ? "black" : ""} key="specialcursor" />
      <NavBar
        darkModeActive={data?.main?.contentTypeName === "jobs"}
        currentPage={{ locale: data?.main?.locale?.locale, localizedUri: data?.main?.uri === "/" ? "/en/" : data?.main?.translated[0]?.uri || data?.main?.translations[0]?.href }}
        childrenPage={{ type: data?.main?.contentTypeName, parentId: data?.main?.parentId }}
        headColor={data?.main?.contentTypeName === "jobs" ? "black" : ""}
        key="navbar"
        location={location}
      />
      <Logo darkModeActive={data?.main?.contentTypeName === "jobs"} location={location} headColor={data?.main?.contentTypeName === "jobs" ? "black" : ""} key="logo" />
      <main className={`bg-black`}>{children}</main>
      <span className="fixed top-0 left-0 right-0 block bg-black" id="transition-overlay"></span>
      <LobDinoGame />
    </>
  )
}

export default Layout
